import { render, staticRenderFns } from "./OttUsAutocomplate.vue?vue&type=template&id=a42977e6&scoped=true"
import script from "./OttUsAutocomplate.vue?vue&type=script&lang=js"
export * from "./OttUsAutocomplate.vue?vue&type=script&lang=js"
import style0 from "./OttUsAutocomplate.vue?vue&type=style&index=0&id=a42977e6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a42977e6",
  null
  
)

export default component.exports