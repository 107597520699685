<template>
  <div class="P-smart-street-block" v-click-outside="closeModal">
    <OttInput
      v-model="address"
      :error="errorAddress"
      @emitFunc="queryAutocompleteForSuggestions"
      :label="isRequiredField ? 'Address*' : 'Address'"
    />
    <div class="P-suggestion-list" v-if="isOpenModal">
      <ul v-if="suggestionList.length && !error">
        <li
          v-for="(list, index) in suggestionList"
          :key="index"
          @click="selectAddress(list)"
        >
          <p>
            {{ list.streetLine }}
            {{
              list.entries > 1
                ? `Apt (more ${list.entries} entries)`
                : list.secondary
            }}
            {{ list.city }}, {{ list.state ? list.state : "" }}
            {{ list.zipcode }}
          </p>
        </li>
      </ul>
      <p class="P-error secondary--text" v-if="error">{{ error }}</p>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapState } from "vuex";
import * as SmartyStreetsSDK from "smartystreets-javascript-sdk";
import * as sdkUtils from "smartystreets-javascript-sdk-utils";
import OttInput from "./OttInput";

const SmartyStreetsCore = SmartyStreetsSDK.core;

export default {
  components: { OttInput },
  props: {
    addressData: {
      type: String,
      default: "",
    },
    errorAddress: {
      type: Boolean,
      default: false,
    },
    isRequiredField: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authId: null,
      authToken: null,
      smartyStreetsSharedCredentials: null,
      autoCompleteClientBuilder: null,
      usStreetClientBuilder: null,
      autoCompleteClient: null,
      usStreetClient: null,

      suggestionList: [],
      selectedData: null,
      shouldValidate: true,
      address: "",
      error: "",
      isOpenModal: false,
      addressList: {
        fullAddress: "",
        address1: "",
        address2: "",
        city: "",
        zipCode: "",
        country: "United States",
        suite: "", //apt
        province: "", //state,
        lat: "",
        long: "",
      },
      isValid: false,
      smartyStreetPublicKey: '',
      smartySrteetAuthId: '',
      smartyStreetAuthToken: '',
    };
  },
  computed: {
    ...mapState({
      addProviderIsOpen: (state) => state.addProviderModal.modal.isOpen,
      clientsModalIsOpen: (state) => state.clientsModule.modal.isOpen,
      manageAdminIsOpen: (state) => state.manageAdminModule.modal.isOpen,
      smartyStreetKeys: (state) => state.otherApiIntegrationAdmin.smartStreet,
      authProviderId: (state) => state.auth.user.provider.id,
    }),
  },

  async created() {
    await this.getOtherApiIntegration(this.authProviderId).then((response) => {
      if (response?.smarty?.key) {
        this.smartyStreetPublicKey = response?.smarty?.key
      }

      if (response?.smarty?.authId) {
        this.smartySrteetAuthId = response?.smarty?.authId
      }

      if (response?.smarty?.authToken) {
        this.smartyStreetAuthToken = response?.smarty?.authToken
      }
    });

    this.authId = this.smartySrteetAuthId
      ? this.smartySrteetAuthId
      : process.env.VUE_APP_SMART_AUTH_ID;
    this.authToken = this.smartyStreetAuthToken
      ? this.smartyStreetAuthToken
      : process.env.VUE_APP_SMART_AUTH_TOKEN;

    // this.smartyStreetsSharedCredentials = new SmartyStreetsCore.StaticCredentials(this.authId, this.authToken);

    this.smartyStreetsSharedCredentials =
      new SmartyStreetsCore.SharedCredentials(this.smartyStreetPublicKey);

    // this.smartyStreetsSharedCredentials = new SmartyStreetsCore.ClientBuilder(new SmartyStreetsCore.StaticCredentials(this.authId, this.authToken));

    this.autoCompleteClientBuilder = new SmartyStreetsCore.ClientBuilder(
      this.smartyStreetsSharedCredentials
    );
    this.usStreetClientBuilder = new SmartyStreetsCore.ClientBuilder(
      this.smartyStreetsSharedCredentials
    );

    this.autoCompleteClient =
      this.autoCompleteClientBuilder.buildUsAutocompleteProClient();
    this.usStreetClient = this.usStreetClientBuilder.buildUsStreetApiClient();
  },

  mounted() {
    setTimeout(() => {
      this.address = this.addressData;
    }, 50);
  },
  // watcher reset addresses
  watch: {
    address() {
      const obj = {
        address: "",
        city: "",
        country: "",
        state: "",
        unit: "",
        zip: "",
      };
      if (this.address.length === 0) {
        if (this.addProviderIsOpen) {
          this.setCompanyAddr({
            formData: obj,
          });
          this.setPayment({
            creditCard: { ...obj, suite: "" },
          });
        } else if (this.clientsModalIsOpen) {
          this.setClientsAddr({
            formData: {
              ...obj,
              suite: "",
              province: "",
            },
          });
          this.setClientsPayment({
            creditCard: { ...obj, suite: "" },
          });
        } else if (this.manageAdminIsOpen) {
          this.setCompanyAdminAddr({
            formData: obj,
          });
          this.setCompanyAdminPayment({
            creditCard: { ...obj, suite: "" },
          });
        }
      }
    },
  },
  methods: {
    ...mapActions({
      getOtherApiIntegration: "otherApiIntegrationAdmin/getOtherApiIntegration",
    }),

    ...mapMutations({
      setCompanyAddr: "companyAddresses/setDataSecondLevels",
      setPayment: "paymentMethods/setDataSecondLevels",
      setClientsAddr: "addressClient/setDataSecondLevels",
      setClientsPayment: "paymentMethodClients/setDataSecondLevels",
      setCompanyAdminAddr: "companyAddressesAdmin/setDataSecondLevels",
      setCompanyAdminPayment: "paymentMethodsAdmin/setDataSecondLevels",
    }),
    openModal() {
      if (this.suggestionList.length) {
        this.isOpenModal = true;
      }
    },
    closeModal() {
      this.isOpenModal = false;
    },

    queryAutocompleteForSuggestions() {
      this.isValid = true;
      this.error = "";
      // 6675 Ethel Ave, North Hollywood CA 91606
      // 330 W Chevy Chase Dr , APT 29 , Glendale CA 91204
      // 13525 Vanowen St , APT 301 Van Nuys CA 91405
      // 229 N Central Ave, Glendale CA 91203

      if (this.address) {
        this.$emit("saveAddress", this.address);
        const lookup = new SmartyStreetsSDK.usAutocompletePro.Lookup(
          this.address
        );
        this.autoCompleteClient
          .send(lookup)
          .then((response) => {
            this.suggestionList = response.result;
            this.isOpenModal = !!this.suggestionList.length;
          })
          .catch((e) => (this.error = e.error));
      }
    },

    selectAddress(list) {
      if (list.entries > 1) {
        const lookup = new SmartyStreetsSDK.usAutocompletePro.Lookup(
          list.streetLine
        );
        lookup.selected =
          list.streetLine +
          " " +
          list.secondary +
          ` (${list.entries}) ` +
          list.city +
          " " +
          list.state +
          " " +
          list.zipcode;
        this.autoCompleteClient
          .send(lookup)
          .then((response) => {
            this.suggestionList = response.result;
          })
          .catch((e) => (this.error = e.error));
      } else {
        this.selectSuggestion(list);
      }
    },

    selectSuggestion(suggestion) {
      this.useAutoCompleteSuggestion(suggestion);
      this.validateAddress(suggestion);
    },
    useAutoCompleteSuggestion() {
      this.isOpenModal = false;
    },

    validateAddress(suggestion) {
      let lookup = new SmartyStreetsSDK.usStreet.Lookup();
      lookup.street = suggestion.streetLine;
      lookup.street2 = suggestion.secondary;
      lookup.add = suggestion.streetLine;
      lookup.city = suggestion.city;
      lookup.state = suggestion.state;
      lookup.zipCode = suggestion.zipCode;
      if (!!lookup.street) {
        this.usStreetClient
          .send(lookup)
          .then(this.updateStateFromValidatedAddress)
          .catch((e) => (this.error = e.error));
      } else {
        this.error = "A street address is required.";
      }
    },
    updateStateFromValidatedAddress(response) {
      const lookup = response.lookups[0];
      this.isValid = sdkUtils.isValid(lookup);
      this.isOpenModal = true;
      if (!this.isValid) {
        this.error = "Please fill in your full address.";
      } else if (this.isValid) {
        const candidate = lookup.result[0];
        this.addressList.address1 = candidate.deliveryLine1;
        // this.addressList.address2 = candidate.deliveryLine2;
        this.addressList.city = candidate.components.cityName;
        if (
          candidate.components.secondaryNumber &&
          candidate.components.secondaryDesignator
        ) {
          this.addressList.suite =
            candidate.components.secondaryDesignator +
            " " +
            candidate.components.secondaryNumber;
        }
        this.addressList.province = candidate.components.state;
        this.addressList.zipCode =
          candidate.components.zipCode + "-" + candidate.components.plus4Code;
        this.error = "";
        this.isOpenModal = false;
        this.address = `${
          candidate.components.primaryNumber
            ? candidate.components.primaryNumber
            : ""
        }${
          candidate.components.streetPredirection
            ? " " + candidate.components.streetPredirection
            : ""
        }${
          candidate.components.streetName ? " " + candidate.components.streetName : ""
        }${
          candidate.components.streetSuffix
            ? " " + candidate.components.streetSuffix
            : ""
        }${
          candidate.components.streetPostdirection
            ? " " + candidate.components.streetPostdirection
            : ""
        }`;

        this.addressList.fullAddress = this.address;
        this.addressList.lat = candidate.metadata.latitude;
        this.addressList.long = candidate.metadata.longitude;
        this.$emit("updateAddress", this.addressList);
      }
    },
    resetAddress() {
      this.address = "";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.P-smart-street-block {
  position: relative;
}


.P-suggestion-list {
  position: absolute;
  background-color: $white;
  max-height: 150px;
  overflow-y: auto;
  z-index: 99;
  border: 1px solid $primary-color;
  border-radius: 4px;
  top: 45px;
  width: 100%;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      padding: 3px 15px;
      cursor: pointer;
      margin: 0;
      font-size: $txt14;

      &:hover {
        background-color: rgba(165, 170, 198, 0.2);
      }

      p {
        margin: 0;
        transition: $transition;
      }
    }
  }
}

.theme--dark {
  .P-suggestion-list {
    background-color: $darkBgLighterDM;
    border-color: transparent;
    box-shadow : 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
    color: white;

    & ul {
      & li:hover {
        color: $primary-colorDM;
        background-color: transparent;
      }
    } 
  }
}

.P-error {
  padding: 15px;
  margin-bottom: 0;
}
</style>